import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainLayoutModule} from './main-layout/main-layout.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NZ_I18N, vi_VN} from 'ng-zorro-antd/i18n';
import {LoginModule} from './login/login.module';
import vi from '@angular/common/locales/vi';
import { registerLocaleData } from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {IdentificationReaderService} from './shared/service/identificationReader.service';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
registerLocaleData(vi);

const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
const config: SocketIoConfig = { url: 'wss://192.168.5.1:8000', options: {} };

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MainLayoutModule,
    LoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [
    { provide: NZ_I18N, useValue: vi_VN },
    IdentificationReaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
