<nz-layout>
  <loading [showLoading]="isLoading"></loading>
  <nz-header class="not-print fixed-top-bar align-items-center p-0 gap-2">
    <div class="logo" style="min-width: 224px; padding-left: 1rem">
      <img alt="" [src]="'/assets/images/logo.png'" width="60px">
    </div>
    <lucide-icon [name]="!isCollapsed ? 'panel-left-close' : 'panel-left-open'" (click)="isCollapsed = !isCollapsed"></lucide-icon>
    <div class="mb-0 w-100"></div>
    <lucide-icon nz-tooltip nzTooltipTitle="Đăng xuất" class="me-2" name="log-out" (click)="logout()"></lucide-icon>
  </nz-header>
  <nz-layout>
    <nz-sider nzCollapsible nzTheme="dark" [nzWidth]="isCollapsed? '81px': '240px'" class="not-print sider-menu fixed-sider" [(nzCollapsed)]="isCollapsed">
      <ul nz-menu nzMode="inline" [ngClass]="isCollapsed? 'sider-menu-collapsed':''" style="max-height: calc(100vh - 48px); overflow-y: auto; overflow-x: hidden">
        <ng-container *ngFor="let menuItem of sideMenuConfig">
          <ng-container *ngIf="menuItem['children'] && menuItem['children'].length; else menuItemTemp">
            <li nz-submenu [nzTitle]="titleTemp" [nzOpen]="menuItem['selected']" class="item-menu">
              <ng-template #titleTemp>
                <span class="ant-menu-title-content">
                  <lucide-icon [ngClass]="{'icon-menu-collapsed': isCollapsed}" *ngIf="menuItem['icon']" [name]="menuItem['icon']" class="me-1"></lucide-icon>
                  {{ menuItem.title | translate }}
                </span>
              </ng-template>
              <ul>
                <li *ngFor="let subMenuItem of menuItem['children']" class="sub-item" routerLink="{{subMenuItem.path}}"
                    nz-menu-item [nzSelected]="subMenuItem['selected']">
                  <lucide-icon [ngClass]="{'icon-menu-collapsed': isCollapsed}" *ngIf="subMenuItem['icon']" [name]="subMenuItem['icon']" class="me-1"></lucide-icon>
                  {{ subMenuItem.title | translate }}
                </li>
              </ul>
            </li>
          </ng-container>
          <ng-template #menuItemTemp>
            <li routerLink="{{menuItem.path}}" nz-menu-item [nzMatchRouter]="true" class="item-menu">
              <lucide-icon [ngClass]="{'icon-menu-collapsed': isCollapsed}" *ngIf="menuItem['icon']" [name]="menuItem['icon']" class="me-1"></lucide-icon>
              {{ menuItem.title | translate }}
            </li>
          </ng-template>
        </ng-container>
      </ul>
    </nz-sider>
    <nz-layout class="inner-layout" [class.is-collapsed]="isCollapsed">
      <nz-content>
        <router-outlet></router-outlet>
      </nz-content>
    </nz-layout>
  </nz-layout>
</nz-layout>
