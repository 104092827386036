import { NgModule } from '@angular/core';
import {MainLayoutComponent} from './main-layout.component';
import {SharedModule} from '../shared/shared.module';
import {NzMenuModule, NzSubMenuComponent} from 'ng-zorro-antd/menu';
import {NzTooltipDirective} from 'ng-zorro-antd/tooltip';
import {DestroyRxjsService} from '../shared/service/destroy-rxjs.service';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    MainLayoutComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    NzSubMenuComponent,
    NzTooltipDirective,
    NzMenuModule,
    NzCollapseModule
  ],
  exports: [
    MainLayoutComponent
  ],
  providers: [
    DestroyRxjsService
  ]
})
export class MainLayoutModule { }
