import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {TokenStorageService} from '../service/token.service';
import {Observable} from 'rxjs';
import moment from 'moment';
import {ProxyDynamicService} from '../service/proxy-dynamic.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private tokenService: TokenStorageService,
    private service: ProxyDynamicService,
    private router: Router,
    private noti: NzNotificationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const auth = this.tokenService.getToken();
    if (auth) {
      const time = this.tokenService.getExpireAt();
      if (time && Number(time) - Number(moment().format('x')) < 3600000) {
        this.service.refreshToken();
      } else if (time && Number(time) - Number(moment().format('x')) <= 0) {
        this.tokenService.signOut();
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }
    this.tokenService.signOut();
    this.router.navigate(['/login']);
    return false;
  }
}
